import * as React from "react";
import Logo from "src/images/icon.svg";

const IndexPage = () => {
  return <main></main>;
};

export default IndexPage;

export const Head: React.FC = () => (
  <>
    <link rel="icon" href={Logo} sizes="any" />
  </>
);
